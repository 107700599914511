import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <Link to="/" className="btn btn__primary">
        Go home
      </Link>
      .
    </Layout>
  )
}

export default NotFoundPage
