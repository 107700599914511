import React from 'react';
import { FaEnvelope, FaHeart } from 'react-icons/fa';
import './footer.scss';
import Logo from '../../images/logo.png';
import { Link } from 'gatsby';

function Footer() {
  return (
    <footer className="footer-distributed mt-80">
      <div className="footer-left">
        <img src={Logo} alt="logo" />

        <p className="footer-links">
          <Link to="/" className="link-1">
            Home &nbsp;
          </Link>
          <Link to="/pricing">Pricing</Link>
        </p>

        <p className="footer-company-name">
          Podkick © {new Date().getFullYear()}
        </p>

        <p className="footer-company-name ">
          Made with <FaHeart color="#fff" size={15} /> in USA
        </p>
      </div>

      <div className="footer-center mt-10">
        <h4 className="text-white">Contact</h4>
        <div className="mt-10 d-flex align-center mobile-justify">
          <p className="mt-5">
            <FaEnvelope color="#fff" />
            &nbsp;
          </p>
          <p>
            <a
              href="mailto:support@podcise.com
"
            >
              support@podcise.com
            </a>
          </p>
        </div>
      </div>

      <div className="footer-right mt-10">
        <h4 className="text-white mb-5">About the company</h4>

        <p className="footer-company-about">
          We love making conversations better.
        </p>

        <div className="footer-links">
          <a
            href="https://app.termly.io/document/terms-of-use-for-saas/a5b1033a-116d-4b6a-b59e-7885db675e94"
            className="link-1"
          >
            Terms of service &nbsp;
          </a>

          <a href="https://app.termly.io/document/privacy-policy/0533b43e-b6f9-42ab-86c8-c2c19c1473c6">
            Privacy policy
          </a>
          <a href="https://app.termly.io/document/cookie-policy/79099f25-b699-4714-9074-2adc9e4e601c">
            Cookie Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
