import React, { useState } from "react";
import "./header.scss";
import Logo from "../../images/logo-white.png";
import { Link } from "gatsby";
import { useMedia } from "react-use";
import { VscClose } from "react-icons/vsc";
import { FaBars } from "react-icons/fa";

function Header() {
  const isMobile = useMedia("(max-width:600px)");
  const [open, setOpen] = useState(false);
  return (
    <nav className="nav container-fluid">
      <div className="nav__logo">
        <div className="nav__logo__container">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
      </div>
      {isMobile && (
        <div className="nav__buttons">
          <button
            className="nav__buttons__btn btn"
            onClick={() => setOpen(true)}
          >
            <FaBars />
          </button>
        </div>
      )}

      {isMobile && open && (
        <>
          <div className="icon_close">
            <VscClose
              onClick={() => {
                setOpen(false);
              }}
              color="purple"
              size={35}
            />
          </div>
          <ul className="mobile__tabs">
            {" "}
            <Link to="/">
              <img src={Logo} width={108} alt="logo" />
            </Link>
            <li className="nav__tabs__tab">
              <Link to="/" activeStyle={{ fontWeight: 700 }}>
                Home
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/pricing" activeStyle={{ fontWeight: 700 }}>
                Pricing
              </Link>
            </li>
            <li>
              <a href="https://documenter.getpostman.com/view/23730133/2s83zfPjpv">
                Documentation
              </a>
            </li>
            <li>
              {" "}
              <a href="https://app.podcise.com/login">Log in</a>
            </li>
            <li>
              {" "}
              <a href="https://app.podcise.com/signup">Sign up</a>
            </li>
          </ul>
        </>
      )}
      {!isMobile && (
        <>
          <ul className="nav__tabs">
            <li className="nav__tabs__tab">
              <Link to="/" activeStyle={{ fontWeight: 700 }}>
                Home
              </Link>
            </li>
            <li className="nav__tabs__tab">
              <Link to="/pricing" activeStyle={{ fontWeight: 700 }}>
                Pricing
              </Link>
            </li>
            <li className="nav__tabs__tab">
              <a href="https://documenter.getpostman.com/view/23730133/2s83zfPjpv">
                Documentation
              </a>
            </li>
          </ul>

          <div className="nav__buttons">
            <a
              href="https://app.podcise.com/login"
              className="nav__as__btn btn btn__light"
            >
              Log in
            </a>
            <a
              href="https://app.podcise.com/signup"
              className="nav__buttons__btn btn btn__primary"
            >
              Sign up
            </a>
          </div>
        </>
      )}
    </nav>
  );
}

export default Header;
